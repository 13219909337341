import request from "./request";

//新增项目付款
export function paymentAdd(data) {
    return request({
        url: '/project/payment',
        method: 'post',
        data
    })
}

//修改项目付款
export function paymentPut(data) {
    return request({
        url: '/project/payment',
        method: 'put',
        data
    })
}

//获取项目付款
export function getPayment(id) {
    return request({
        url: '/project/payment/' + id,
        method: 'get'
    })
}

//删除项目付款
export function paymentDeltel(ids) {
    return request({
        url: '/project/payment/' + ids,
        method: 'delete'
    })
}

//导出项目付款
export function paymentExport(data) {
    return request({
        url: '/project/payment/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

//导入项目付款
export function paymentImportData(data) {
    return request({
        url: '/project/payment/importUpdateType',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

//获取项目付款列表
export function paymentList(query) {
    return request({
        url: '/project/payment/list',
        params: query
    })
}

//财务最后确认步骤
export function paymentUpdateState(ids, isSettle) {
    return request({
        url: `/project/payment/updateState/${ids}/${isSettle}`,
        method: 'put'
    })
}