import request from "./request";

//新增项目报销
export function reimbursementAdd(data) {
    return request({
        url: '/project/reimbursement',
        method: 'post',
        data
    })
}

//修改项目报销
export function reimbursementPut(data) {
    return request({
        url: '/project/reimbursement',
        method: 'put',
        data
    })
}

//获取项目报销
export function getReimbursement(id) {
    return request({
        url: '/project/reimbursement/' + id,
        method: 'get'
    })
}

//删除项目报销
export function reimbursementDeltel(ids) {
    return request({
        url: '/project/reimbursement/' + ids,
        method: 'delete'
    })
}

//导出项目报销
export function reimbursementExport(data) {
    return request({
        url: '/project/reimbursement/export',
        method: 'post',
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data
    })
}

//导入项目报销
export function reimbursementImportData(data) {
    return request({
        url: '/project/reimbursement/importUpdateType',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

//获取项目报销列表
export function reimbursementList(query) {
    return request({
        url: '/project/reimbursement/list',
        params: query
    })
}

//财务最后确认接口 
//财务最后确认步骤
export function reimbursementUpdateState(ids, isSettle) {
    return request({
        url: `/project/reimbursement/updateState/${ids}/${isSettle}`,
        method: 'put'
    })
}