<template>
  <div>
    <div class="bkColor">
      <headTitle :title="pjDetail.projectName + title" :routerPath="'/pjList'"></headTitle>
      <div class="projectTitle">
        <div class="T">{{ pjDetail.personInCharge }}的项目信息</div>
        <div class="D">
          {{ pjDetail.userName }} 提交于 {{ pjDetail.createTime }} <van-button plain type="info" size="mini" round
            @click="addBudget = true" class="addBtn" v-if="powerList.includes('1')">增加项目预算</van-button>
        </div>

        <van-popup v-model="addBudget" position="bottom"
          :style="{ height: '40%', paddingTop: '40px', background: '#f6f6f6' }" closeable>
          <van-field v-model="pjDetail.projectName" readonly rows="1" autosize type="textarea" label="新增预算项目"
            placeholder="请输入" />
          <van-field v-model="budgetFrom.additionalBudget" label="新增项目预算（元）" type="number" placeholder="请输入" />
          <div
            style="width: 100%; margin-top: 20px;padding: 20px 0;background: #fff;display: flex;justify-content: space-around;">
            <van-button round block color="#CCCCCC" native-type="button" style="width: 35vw"
              @click="addBudget = false">取消</van-button>
            <van-button round block type="info" native-type="button" style="width: 35vw"
              @click="addPrice">添加预算</van-button>
          </div>
        </van-popup>
        <van-notify v-model="contentShow" :type="contentType" :style="{ marginTop: (equipment ? '7vh' : 0) }">
          <span>{{ content }}</span>
        </van-notify>
      </div>
      <div class="projectBody">
        <van-field v-model="pjDetail.projectName" readonly rows="1" autosize type="textarea" label="项目名称" />
        <van-field v-model="pjDetail.projectNo" readonly label="项目编号" />
        <van-field v-model="pjState" readonly label="项目状态" />
        <van-field v-model="pjDetail.year" readonly label="所属年份" />
        <van-field v-model="pjDetail.belong" readonly label="项目归属" />
        <van-field v-model="permissions" readonly rows="1" autosize type="textarea" label="项目权限" />

        <downloadFile :fileList="pjDetail.fileList" :routerPath="`/pjDetails?id=${queryFrom.projectId}`"></downloadFile>
      </div>
      <div class="projectTap">
        <van-tabs v-model="active" @click="cardSwich">
          <van-tab title="详细信息" class="message">
            <van-cell title="项目负责人" :value="pjDetail.personInCharge" />
            <van-cell title="客户" :value="pjDetail.custom" />
            <van-cell title="项目类型" :value="pjDetail.projectTypeName" />
            <van-cell title="项目地址" :value="projectAddress" />
            <van-cell title="项目金额（元）" :value="pjDetail.price" />
            <van-cell title="项目已付款（元）" :value="pjDetail.summary.paymentTotalPrice" />
            <van-cell title="成本预算（元）" :value="pjDetail.costBudget" />
            <van-cell title="追加成本预算（元）" :value="pjDetail.additionalBudget" />
            <van-cell title="计划开始日期" :value="pjDetail.startTime" />
            <van-cell title="计划结束日期" :value="pjDetail.endTime" />
            <van-cell title="项目费率" :value="projectRate" />
            <van-cell title="行政费率" :value="pjDetail.administrationRate + '%'" />
            <van-cell title="市场费率" :value="pjDetail.marketRate + '%'" />
            <van-cell title="交通费率" :value="pjDetail.trafficRate + '%'" />
            <van-cell title="税率" :value="pjDetail.taxRate + '%'" />
            <van-cell title="工资费率" :value="pjDetail.salaryRate + '%'" />
          </van-tab>
          <van-tab title="进度汇报">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                finished-text="没有更多了" @load="getProgress">
                <div class="listCell" v-for="item in pjProgressList" :key="item.id" @click="goDetails(item.id)">
                  <div class="cellName">{{ item.projectName }}</div>
                  <div class="cellText">
                    项目进度：{{ getProjectStatus(item.projectStatus) }}
                  </div>

                  <div class="cellText">汇报时间：{{ item.createTime }}</div>
                </div>
              </van-list>
            </van-pull-refresh>
          </van-tab>
          <van-tab title="项目收款">
            <div class="sectionTitle">统计 <span>收款总额</span> {{ amountDisplay(pjDetail.summary.collectTotalPrice) }}</div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                finished-text="没有更多了" @load="getCollection">
                <div class="listCell" v-for="item in pjGatheringList" :key="item.id" @click="goDetails(item.id)">
                  <div class="cellName">
                    {{ item.description }}
                    <div class="status">
                      <span :class="{ 'blue': item.state == 0, 'green': item.state == 1, 'red': item.state == 2 }"> {{
                        stateText(item.state) }}</span><br />
                      <span
                        :class="{ 'blue': item.isSettle == 0, 'green': item.isSettle == 1, 'red': item.isSettle == 2 }">{{
                          settlement(item.isSettle) }}</span>
                    </div>
                  </div>
                  <div class="cellText">收款金额：{{ item.price }}元</div>
                  <div class="cellText">收款日期：{{ item.collectionTime }}</div>
                </div>
              </van-list>
            </van-pull-refresh>
          </van-tab>
          <van-tab title="项目付款">
            <div class="sectionTitle">统计 <span>付款总额</span> {{ amountDisplay(pjDetail.summary.paymentTotalPrice) }}</div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                finished-text="没有更多了" @load="getPaymentList">
                <div class="listCell" v-for="item in pjPaymentList" :key="item.id" @click="goDetails(item.id)">
                  <div class="cellName">
                    {{ item.description }}
                    <div class="status">
                      <span :class="{ 'blue': item.state == 0, 'green': item.state == 1, 'red': item.state == 2 }"> {{
                        stateText(item.state) }}</span><br />
                      <span
                        :class="{ 'blue': item.isSettle == 0, 'green': item.isSettle == 1, 'red': item.isSettle == 2 }">{{
                          settlement(item.isSettle) }}</span>
                    </div>
                  </div>
                  <!-- <div class="cellText">付款说明：<div style="width:25vw;display: inline-block;  vertical-align: top;">
                    {{ item.description }}
                  </div>
                </div> -->
                  <div class="cellText">付款金额：{{ item.price }}元</div>
                  <div class="cellText">付款日期：{{ item.paymentTime }}</div>
                </div>
              </van-list>
            </van-pull-refresh>
          </van-tab>
          <van-tab title="项目报销">
            <div class="sectionTitle">统计 <span>报销总额</span> {{ amountDisplay(pjDetail.summary.reimbursementTotalPrice) }}
            </div>
            <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
              finished-text="没有更多了" @load="getreimbursementList">
              <div class="listCell" v-for="item in pjReimbursementList" :key="item.id" @click="goDetails(item.id)">
                <div class="cellName">
                  {{ item.reason }}
                  <div class="status">
                    <span :class="{ 'blue': item.state == 0, 'green': item.state == 1, 'red': item.state == 2 }"> {{
                      stateText(item.state) }}</span><br />
                    <span
                      :class="{ 'blue': item.isSettle == 0, 'green': item.isSettle == 1, 'red': item.isSettle == 2 }">{{
                        settlement(item.isSettle) }}</span>
                  </div>
                </div>
                <!-- <div class="cellText">报销备注：<div style="width:25vw;display: inline-block;  vertical-align: top;">
                  {{ item.reason }}
                </div>
              </div> -->
                <div class="cellText">报销人：{{ item.userName }}</div>
                <div class="cellText">报销金额：{{ item.totalPrice }}元</div>
                <div class="cellText">报销发起日期：{{ item.createTime }}</div>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>



      <van-popup v-model="isSetPermissions" position="bottom">
        <div style="
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            justify-content: space-between;
          ">
          <div @click="isSetPermissions = false">取消</div>
          <div style="color: #576b95" @click="assignPermissions">确认修改</div>
        </div>
        <div style="background:#f6f6f6;padding: 20px">已选择：{{ permissions }}</div>
        <van-tree-select :items="allUserList" :active-id.sync="permissionList" @click-item="addPermission" />
      </van-popup>
    </div>
    <van-goods-action>
      <van-goods-action-icon v-if="powerList.includes('1') || this.userId == pjDetail.chargeId" icon="friends-o"
        text="分配项目权限" @click="isSetPermissions = true" />
      <van-goods-action-icon v-if="powerList.includes('1')" icon="records" text="修改项目信息" @click="updateProject" />
    </van-goods-action>
  </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import { getInfo, getAccessPower, accessPowerPut } from "../api/info";
import { progressList } from "../api/progress";
import { gatheringList } from "../api/gathering";
import { paymentList } from "../api/payment";
import { reimbursementList } from "../api/reimbursement";
import { getToken } from "../units/auth"
import { tenThousand } from '../units/units'
import { additionalBudget, getAllUserList } from '../api/api'

import { pjStateText, projectStatusText, settlementText } from "../units/units";

import { Field, Tab, Tabs, Cell, List, PullRefresh, Icon, Notify, Button, Popup, GoodsAction, GoodsActionIcon, Dialog, TreeSelect, Toast } from "vant";

import downloadFile from "../components/downloadFile.vue";
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Icon);
Vue.use(Notify)
Vue.use(Button)
Vue.use(Popup)
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(Dialog)
Vue.use(TreeSelect)
export default {
  name: "pjDetails",
  components: {
    headTitle,
    downloadFile
  },
  data() {
    return {
      title: "的项目详情",
      active: 0,
      userId: '',
      queryFrom: {
        pageSize: 10,
        pageNum: 1,
        projectId: null,
      },
      pjDetail: {
        projectName: '',
        summary: {
          paymentTotalPrice: 0
        }

      }, //详情对象
      pjProgressList: [], //项目进度列表
      pjGatheringList: [], //项目收款列表
      pjPaymentList: [], //项目付款列表
      pjReimbursementList: [], //项目报销列表
      projectName: "",
      pjNumber: "",
      pjState: "",
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      list: [],
      projectRate: '',
      powerList: [],//value 1总经理 2财务 3行政
      addBudget: false,//增加项目预算时的弹框
      budgetFrom: {
        id: null,
        additionalBudget: 0,
      },//追加预算
      equipment: false,//客户端判断
      contentShow: false,//是否显示提醒
      content: '',//通知内容
      contentType: 'warning',
      isReviewImg: false,//是否预览图片
      reViewImg: '',//预览的图片
      isSetPermissions: false,
      permissions: '',//项目权限人
      permissionList: [],//项目权限列表
      allUserList: [{ text: '人员列表', children: [] }],
      projectAddress: ''

    };
  },
  watch: {
    "pjDetail.projectState": {
      handler(newVal) {
        this.pjState = pjStateText(newVal);
      },
    },
  },
  mounted() {
    this.isMobile()
    this.queryFrom.projectId = this.$route.query.id;
    this.getPjDetail();
    this.getPowerList()
    this.getAccessPowerList()
    this.getUserList()
    this.getUserId()
  },
  destroyed() {
    Notify.clear()
  },
  methods: {
    //金额转换
    amountDisplay(p) {
      return tenThousand(p)
    },
    //财务是否结算
    settlement(f) {
      return settlementText(f)
    },
    isMobile() {
      this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
    },
    //获取权限渲染页面
    getPowerList() {
      this.powerList = JSON.parse(getToken('powerList') || '[]')
    },
    getUserId() {
      this.userId = getToken('userId')
    },


    //添加预算
    addPrice() {
      Dialog.confirm({
        title: '提示',
        message: '确认该项目设置新增项目预算吗？',
        confirmButtonColor: '#1989fa'
      })
        .then(() => {
          // on confirm
          this.budgetFrom.id = this.pjDetail.id
          additionalBudget(this.budgetFrom.id, this.budgetFrom.additionalBudget).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.addBudget = false
              this.budgetFrom.additionalBudget = 0
              this.getPjDetail()
            }
          })
        })
        .catch(() => {

        });
    },
    //项目信息详情
    getPjDetail() {
      getInfo(this.queryFrom.projectId).then((res) => {
        this.pjDetail = res.data.data;
        this.projectAddress = this.pjDetail.city + this.pjDetail.address
        console.log(this.pjDetail.costBudget / this.pjDetail.price) * 100
        this.projectRate = parseFloat((this.pjDetail.costBudget / this.pjDetail.price * 100).toFixed(2)) + '%' || '0%'
        if (this.pjDetail.budgetPercent >= 70 && 80 > this.pjDetail.budgetPercent) {
          // Notify({ type: 'warning', message: '项目准备进入预警状态' });
          this.content = "项目准备进入预警状态"
          this.contentType = 'warning'
          this.contentShow = true
          setTimeout(() => {
            this.contentShow = false;
          }, 3000)
        } else if (this.pjDetail.budgetPercent >= 80 && this.pjDetail.budgetPercent < 100) {
          // Notify({ type: 'danger', message: '项目已超额，请追加预算', duration: 'notify' });
          this.content = `已支出费用占成本预算的${this.pjDetail.budgetPercent}%，请注意控制支出`
          this.contentType = 'danger'
          this.contentShow = true
        } else if (res.data.data.budgetPercent >= 100) {
          this.content = `支出费用已超出预算，无法付款，请申请增加预算`;
          this.contentType = "danger";
          this.contentShow = true;
        }
      });
    },
    //获取项目访问权限的用户列表
    getAccessPowerList() {
      getAccessPower(this.queryFrom.projectId).then(res => {
        this.permissions = ''
        this.permissionList = []
        if (res.data.code == 200) {
          res.data.rows.forEach((it, idx) => {
            if (idx > 0) {
              this.permissions = this.permissions + ','
            }
            this.permissions = this.permissions + it.name
            this.permissionList.push(it.userId)
          })
        }
      })
    },

    //收款
    getCollection() {
      this.loading = true;
      gatheringList(this.queryFrom).then((res) => {
        this.pjGatheringList = this.pjGatheringList.concat(res.data.rows)
        this.refreshing = false;
        this.loading = false
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
        console.log(this.queryFrom.pageNum * this.queryFrom.pageSize);
        if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
          this.finished = true;
        }
      });
    },
    //付款
    getPaymentList() {
      this.loading = true;
      paymentList(this.queryFrom).then((res) => {
        this.pjPaymentList = this.pjPaymentList.concat(res.data.rows)
        this.refreshing = false;
        this.loading = false
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;

        if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
          this.finished = true;
        }
      });
    },
    //进度
    getProgress() {
      this.loading = true;
      progressList(this.queryFrom).then((res) => {
        this.pjProgressList = this.pjProgressList.concat(res.data.rows)
        this.refreshing = false;
        this.loading = false
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
        console.log(this.queryFrom.pageNum * this.queryFrom.pageSize);
        if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
          this.finished = true;
        }
      });
    },
    //报销
    getreimbursementList() {
      this.loading = true;
      reimbursementList(this.queryFrom).then((res) => {
        this.pjReimbursementList = this.pjReimbursementList.concat(res.data.rows)
        this.refreshing = false;
        this.loading = false
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;

        if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
          this.finished = true;
        }
      });
    },
    //获取项目审核状态
    stateText(t) {
      return projectStatusText(t);
    },
    //项目状态
    getProjectStatus(t) {
      return pjStateText(t)
    },
    //标签卡片切换
    cardSwich() {
      this.loading = false
      this.finished = false
      this.refreshing = false
      this.error = false
      this.queryFrom.pageNum = 1
      switch (this.active) {
        case 1:
          this.pjProgressList = []
          this.getProgress()
          break;
        case 2:
          this.pjGatheringList = []
          this.getCollection()
          break;
        case 3:
          this.pjPaymentList = []
          this.getPaymentList()
          break;
        case 4:
          this.pjReimbursementList = []
          this.getreimbursementList()
          break
      }
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.queryFrom.pageNum = 1
      // 重新加载数据

      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      //   this.onLoad();
      this.cardSwich()
    },
    goDetails(id) {
      switch (this.active) {
        case 1:
          this.$router.push({
            path: '/progressDetails', query: { id: id, route: `/pjDetails?id=${this.queryFrom.projectId}` }
          })
          break
        case 2:
          this.$router.push({
            path: '/collectionDetails', query: { id: id, route: `/pjDetails?id=${this.queryFrom.projectId}` }
          })
          break
        case 3:
          this.$router.push({
            path: '/paymentDetails', query: { id: id, route: `/pjDetails?id=${this.queryFrom.projectId}` }
          })
          break
        case 4:
          this.$router.push({
            path: '/reimbursementDetails', query: { id: id, route: `/pjDetails?id=${this.queryFrom.projectId}` }
          })
          break
      }
    },
    //项目分配权限
    assignPermissions() {
      accessPowerPut(this.permissionList, this.queryFrom.projectId).then(res => {
        if (res.data.code == 200) {
          Toast.success('修改成功')
          this.getAccessPowerList()
          this.isSetPermissions = false
        }
      })
    },
    getUserList() {
      getAllUserList().then(res => {
        if (res.data.code == 200) {
          this.allUserList[0].children = res.data.rows.map(it => {
            return {
              text: it.name,
              id: it.userId
            }
          })
        }
      })
    },
    addPermission(data) {
      if (this.permissions.includes(data.text)) {
        let text = this.permissions.includes(',' + data.text) ? ',' + data.text : this.permissions.includes(data.text + ',') ? data.text + ',' : data.text
        this.permissions = this.permissions.replace(text, '')
      } else {
        this.permissions = this.permissions + (this.permissions == '' ? '' : ',') + data.text
      }
    },
    updateProject() {
      this.$router.push({
        path: '/newPj', query: { projectId: this.queryFrom.projectId, route: `/pjDetails?id=${this.queryFrom.projectId}` }
      })
    }
  },

};
</script>

<style lang="less" scoped>
.blue {
  color: #1989fa
}

.red {
  color: #E86F61;
}

.green {
  color: #00AB45;
}


.bkColor {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 50px;
  background: #f6f6f6;
  position: relative;
  overflow: auto;
  ;
}

.projectTitle {
  width: 100%;
  background: #fff;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 5vh;
  margin-bottom: 2vh;
  position: relative;

  .T {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.5vh;
  }

  .D {
    color: #797979;
    font-size: 0.5rem;
  }

  .addBtn {
    float: right;
    margin-right: 5vw;
    vertical-align: middle;
  }
}

.projectBody {
  background: #fff;
  margin-bottom: 2vh;
}

.projectTap {
  min-height: 67vh;
}

.projectTap .message /deep/ .van-cell__title {
  text-align: left;
}

/deep/ .van-tabs__content {
  background: #fff;
}

.listCell {
  width: 100%;
  box-sizing: border-box;
  padding-left: 5vw;
  padding-right: 5w;
  background: #fff;
  text-align: left;
  position: relative;
}

.cellName {
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  border-top: 2px solid #f6f6f6;
  padding-top: 1vh;
  padding-bottom: 1vh;
  position: relative;

  .status {
    position: absolute;
    right: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: right;
  }
}

/deep/ .progress :first-of-type.listCell .cellName {
  border-top: unset;
}

.cellText {
  width: 70%;
  color: #797979;
  margin-bottom: 0.5vh;
  font-size: 0.7rem;
  word-break: break-all;
  line-height: 1;
}

.sectionTitle {
  font-weight: 600;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 15px;
  padding-top: 10px;

  span {
    font-weight: 500;
    color: #666666;
  }
}

.fileList {
  width: 100%;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 14px;
  color: #797979;

  .title {
    text-align: left;
  }

  .fileImg {
    width: 80px;
    height: 80px;
  }

  .fileName {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/deep/ .van-goods-action {
  background: #f6f6f6;
}

/deep/ .van-goods-action-icon {
  min-width: 20%;
  background: #f6f6f6;
}

/deep/ .van-tree-select__nav {
  display: none;
}

/deep/ .van-tree-select__item--active {
  color: #1989fa
}
</style>