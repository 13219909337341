import request from "./request";

//新增项目收款
export function gatheringAdd(data) {
    return request({
        url: '/project/gathering',
        method: 'post',
        data
    })
}

//修改项目收款
export function gatheringPut(data) {
    return request({
        url: '/project/gathering',
        method: 'put',
        data
    })
}

//获取项目收款
export function getGathering(id) {
    return request({
        url: '/project/gathering/' + id,
        method: 'get'
    })
}

//删除项目收款
export function gatheringDeltel(ids) {
    return request({
        url: '/project/gathering/' + ids,
        method: 'delete'
    })
}

//导出项目收款
export function gatheringExport(data) {
    return request({
        url: '/project/gathering/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

//导入项目收款
export function gatheringImportData(data) {
    return request({
        url: '/project/gathering/importData',
        method: 'post',
        data
    })
}

//获取项目收款列表
export function gatheringList(query) {
    return request({
        url: '/project/gathering/list',
        params: query
    })
}
