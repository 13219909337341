<template>
    <div class="fileList" v-show="fileList">
        <div class="title">附件 <van-button round block type="info" native-type="button" style="width: 120px;float: right;"
                @click="downloadAll" size="small" v-if="equipment">下载全部文件</van-button></div>

        <div style="display:flex;flex-wrap: wrap;">
            <div v-for="it in  fileList" :key="it.id"
                style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <img class="fileImg" src="../assets/icon/file.png" alt="" @click="reViewFile(it.url)">
                <div class="fileName" @click="reViewFile(it.url)">{{ it.fileName }}</div>
                <van-button round block hairline plain type="info" native-type="button"
                    style="width: 65px;margin: 10px 0;float: right;" @click="downloadFile(it)" size="mini">下载文件</van-button>
            </div>
        </div>
        <div v-show="isReviewImg"
            style="position: fixed;top: 0;left:0;right:0;bottom:0;margin:auto;width: 100%;height: 100%;background: #323233;z-index: 1;overflow: auto;"
            @click="isReviewImg = false">
            <img :src="reViewImg" alt=""
                style="width: 100%;position: absolute;top: 0;left: 0;bottom: 0;right: 0;" />
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import { Button } from 'vant'
Vue.use(Button)


export default {
    props: {
        fileList: {
            typeof: Array,
            default: () => {
                return []
            }
        },
        routerPath: {
            typeof: String,
            default: '/'
        }
    },
    data() {
        return {
            isReviewImg: false,//是否预览图片
            reViewImg: '',
            equipment: false
        }
    },
    mounted() {
        this.isMobile()
    },
    methods: {
        downloadFile(file) {
            if (this.equipment) {
                fetch(file.url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(blob)
                    a.download = file.fileName  // 下载文件的名字
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a) // 下载完成移除元素

                })
            } else {
                let link = document.createElement('a')
                link.style.display = 'none'
                let url = file.url//绝对地址
                link.href = url
                link.download = ''//文件名
                document.head.appendChild(link)
                link.click()
                document.head.removeChild(link)
            }
        },
        downloadAll() {
            this.fileList.forEach(it => {
                this.downloadFile(it)
            })

        },
        reViewFile(url) {
            if (url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.png') !== -1) {
                this.isReviewImg = true
                this.reViewImg = url
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: url, routerPath: this.routerPath },
            });
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    }
}
</script>

<style lang="less" scoped>
.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 2vh;
}
</style>