import request from "./request";

//新增项目进度
export function progressAdd(data) {
    return request({
        url: '/project/progress',
        method: 'post',
        data
    })
}

//修改项目进度
export function progressPut(data) {
    return request({
        url: '/project/progress',
        method: 'put',
        data
    })
}

//获取项目进度
export function getProgress(id) {
    return request({
        url: '/project/progress/' + id,
        method: 'get'
    })
}

//删除项目进度
export function progressDeltel(ids) {
    return request({
        url: '/project/progress/' + ids,
        method: 'delete'
    })
}

//导出项目进度
export function progressExport() {
    return request({
        url: '/project/progress/export',
        method: 'post'
    })
}

//导入项目进度
export function progressImportData(data) {
    return request({
        url: '/project/progress/importData',
        method: 'post',
        data
    })
}

//获取项目进度列表
export function progressList(query) {
    return request({
        url: '/project/progress/list',
        params: query
    })
}
